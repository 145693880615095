<template>
  <div>
    <div class="content">
      <group-login-register
        :open="true"
        @isLogin="isLogin"
        @switchLoginType="switchLoginType"
      />
    </div>
  </div>
</template>

<script>
import GroupLoginRegister from '@/components/GroupLoginRegister.vue'
export default {
  auth: false,
  components: {
    GroupLoginRegister
  },
  beforeRouteLeave (to, from, next) {
    if (this.loginType === 'login' && !this.loginSuccess) {
      this.$gtm.push({ event: 'loginLeave' })
    } else if (this.loginType === 'register' && !this.registerSuccess) {
      this.$gtm.push({ event: 'registerLeave' })
    }
    next()
  },
  middleware: 'postRequest',
  meta: {
    mobileBanner: false
  },
  data () {
    return {
      title: '會員登入',
      isMobile: null,
      loginType: null,
      loginSuccess: false,
      registerSuccess: false
    }
  },
  head () {
    return {
      title: this.title,
      meta: [
        { hid: 'title', property: 'title', content: this.title + this.$store.state.meta.titleTemple },
        { hid: 'og:title', property: 'og:title', content: this.title + this.$store.state.meta.titleTemple },
        { hid: 'twitter:title', property: 'twitter:title', content: this.title + this.$store.state.meta.titleTemple }
      ]
    }
  },
  async mounted () {
    this.isMobile = screen.width <= 768
    if (process.client && this.$auth.loggedIn) {
      const toRoute = this.$auth.$storage.getState('toRoute') || { name: 'member-accountSetting' }
      console.log('toRoute', toRoute)
      this.$nuxt.$router.replace(toRoute)
    }
  },
  methods: {
    async isLogin (prop) {
      if (prop === 'register') {
        this.registerSuccess = true
        // await this.$nuxt.$router.replace('/')
        // await this.$store.dispatch('registerSuccess', {isOpen: true, scrollTop: true})
        this.$gtm.push({
          event: 'userRegister',
          pageTitle: document.title,
          pageUrl: this.$nuxt.$route.path,
          routeName: this.$nuxt.$route.name
        })
        console.log(this.$auth.$storage.getState('toRoute'))
        let toRoute = this.$auth.$storage.getState('toRoute') || { name: 'member-accountSetting' }
        if (sessionStorage.getItem('toRoute')) {
          toRoute = JSON.parse(sessionStorage.getItem('toRoute'))
          sessionStorage.removeItem('toRoute')
        }
        if (sessionStorage.getItem('serviceData')) {
          sessionStorage.setItem('serviceData', JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('serviceData')),
            isLogined: true
          }))
        }
        this.$nuxt.$router.replace(toRoute)
        this.$auth.$storage.removeState('toRoute')
        await this.$store.dispatch('registerSuccess', { isOpen: true, scrollTop: true })
      } else {
        this.loginSuccess = true
        this.$gtm.push({
          event: 'userLogin',
          pageTitle: document.title,
          pageUrl: this.$nuxt.$route.path,
          routeName: this.$nuxt.$route.name
        })
        const redirectRoutePath = this.$route.query.redirect
        let toRoute = this.$auth.$storage.getState('toRoute') || redirectRoutePath || { name: 'member-accountSetting' }
        if (sessionStorage.getItem('toRoute')) {
          toRoute = JSON.parse(sessionStorage.getItem('toRoute'))
          sessionStorage.removeItem('toRoute')
        } else if (redirectRoutePath) {
          toRoute = redirectRoutePath
        }
        if (sessionStorage.getItem('serviceData')) {
          sessionStorage.setItem('serviceData', JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('serviceData')),
            isLogined: true
          }))
        }
        this.$nuxt.$router.replace(toRoute)
        this.$auth.$storage.removeState('toRoute')
        this.$store.dispatch('order/checkHasOrderIsQuotedAndUnread')
      }
    },
    switchLoginType (type) {
      this.loginType = type
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
}

/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .content {
    height: 100%;
    box-sizing: border-box;
  }
}

/** 手機以上 */
@media screen and (min-width: $mobileWidth) {
  .content {
    max-width: 530px;
    margin: 40px auto 50px;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
  }
}
</style>
